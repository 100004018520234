import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import BoxIcon from '../../../assets/claims/images/claim-received/BoxIcon';
import { trackClaimReceivedViewEvent } from '../../../analytics/Analytics';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import Feedback from '../../../components/feedback/Feedback';
import { FeedbackData, FeedbackLinkCaseType } from '../../../api';
import { getClaimFeedbackUuidAction, submitFeedbackAction } from '../../feedback/feedbackActions';
import { isGetClaimSubmissionFeedbackEnabled } from './ServiceNotificationActions';

export const ClaimReceived: React.FC<ClaimReceivedProps> = ({
  claimId,
  color,
  isMotionsCloudEnabled,
  getClaimFeedbackUuid,
  submitFeedback,
  isGetFeedbackEnabled,
}: ClaimReceivedProps) => {
  const [feedbackPopup, setFeedbackPopup] = useState(<></>);
  const [feedbackUuid, setFeedbackUuid] = useState('');
  useEffect(() => {
    trackClaimReceivedViewEvent(claimId);
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const setupFeedbackPopup = () => {
    if (isMotionsCloudEnabled) return;
    if (claimId) {
      isGetFeedbackEnabled(claimId).then((serviceNotificationResponse) => {
        if (serviceNotificationResponse.isEnabled) {
          getClaimFeedbackUuid(claimId)
            .then((uuid) => {
              setFeedbackUuid(uuid);
              setFeedbackPopup(
                <ModalPopup
                  message={<Feedback onSubmit={onFeedbackSubmitted} color={color} />}
                  closeHandler={() => setFeedbackPopup(<></>)}
                  closeText={<Translate value={'close'} />}
                  hideCloseButton={true}
                />
              );
            })
            .catch((rejection) => console.error('could not get feedback uuid : ' + rejection));
        }
      });
    }
  };

  useEffect(() => {
    setupFeedbackPopup();
  }, [feedbackUuid]);

  const onFeedbackSubmitted = async (feedbackData: FeedbackData) => {
    try {
      await submitFeedback(feedbackUuid, FeedbackLinkCaseType.Claim, feedbackData);
      setFeedbackPopup(<></>);
    } catch (e: any) {
      console.error('Failed to submit feedback!');
    }
  };

  return (
    <Layout>
      <Container className='content yaway-container' fluid>
        <Container className={'text-center'} style={{ margin: '10% auto 0 auto' }}>
          <BoxIcon />
        </Container>
        <SectionHeader text={<Translate value={'claimReceived.title'} />} />
        <Container className='col-sm-10 col-md-6 col-lg-6' fluid>
          <p style={{ textAlign: 'center' }}>
            <Translate value={'claimReceived.body'} />
          </p>
        </Container>
        {feedbackPopup}
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ claim, theme }: AppState) => ({
  claimId: claim.claimId,
  color: theme.color,
  getClaimFeedbackUuid: getClaimFeedbackUuidAction,
  submitFeedback: submitFeedbackAction,
  isGetFeedbackEnabled: isGetClaimSubmissionFeedbackEnabled,
  isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
});

export type ClaimReceivedProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ClaimReceived);
