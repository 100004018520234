import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../../common/components/incidents/incident.css';
import { IncidentList } from './IncidentTypeList';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../../store/store';
import Layout from '../../../components/layouts/Layout';
import { Translate } from 'react-redux-i18n';
import Incident, { IncidentProps } from '../../../common/components/incidents/Incident';
import { ApiClaimIncidentType } from '../../../api';
import { saveClaimIncident } from './incidentActions';
import { push } from 'connected-react-router';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { getLocalizedMessageElement } from '../../../locale/locale';
import { trackClaimIncidentViewEvent } from '../../../analytics/Analytics';
import { Loader } from '../../../components/loader/Loader';

export const incidentSelectionErrors = {
  INVALID_CLAIM: 'Invalid Claim!',
};

export const IncidentSelection: React.FC<IncidentSelectionProps> = ({
  color,
  claimId,
  setIncident,
  selectedIncident,
  navigateToNextStep,
}: IncidentSelectionProps) => {
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    trackClaimIncidentViewEvent(claimId || null);
  }, []);

  const onSelect = async (incident: ApiClaimIncidentType) => {
    setDisabled(true);
    if (claimId != undefined) {
      await setIncident(incident, claimId);
      navigateToNextStep('/claims/vehicle-details');
    } else {
      setErrorPopup(
        <ModalPopup
          message={getLocalizedMessageElement(incidentSelectionErrors.INVALID_CLAIM)}
          closeHandler={() => navigateToNextStep('/')}
        />
      );
    }
    setDisabled(false);
  };

  return (
    <Layout showProgress>
      {errorPopup}
      <Container
        className={disabled ? 'content yaway-container disabled-area' : 'content yaway-container'}
        fluid
      >
        <SectionHeader text={<Translate value={'claimIncident.WHAT_IS_THE_ISSUE'} />} />
        {disabled && <Loader />}
        <Row className='py-auto mx-auto w-sm-75 w-md-100'>
          {IncidentList.map((incident: IncidentProps, index: number) => (
            <Col xs={6} md={4} key={index}>
              <Incident
                icon={incident.icon}
                text={<Translate value={`claimIncident.${incident.text}`} />}
                onClick={() => onSelect(incident.text as ApiClaimIncidentType)}
                isSelected={incident.text == selectedIncident}
                color={color}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

const mapDispatchToProps = {
  setIncident: saveClaimIncident,
  navigateToNextStep: (path: string) => push(path),
};

const mapStateToProps = ({ theme, claimIncident, claim }: AppState) => ({
  color: theme.color,
  selectedIncident: claimIncident.incident,
  claimId: claim.claimId,
});

export type IncidentSelectionProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentSelection);
