import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container, Form } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { AppState } from '../../../store/store';
import NextButton from '../../../components/controls/NextButton';
import { FormProvider, useForm } from 'react-hook-form';
import { TimeInput } from './TimePicker';
import { DateInput } from './DatePicker';
import { Radio } from '../../../components/controls/Radio';
import { TextAreaInput } from '../../../components/controls/TextAreaInput';
import { ApiAccidentCulprit, ApiAccidentDetails, ApiClaimIncidentType } from '../../../api';
import { saveAccidentDetailsAction } from './accidentDetailsAction';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { push } from 'connected-react-router';
import { Translate } from 'react-redux-i18n';
import './AccidentDetails.css';
import {
  trackClaimAccidentDetailsSubmitEvent,
  trackClaimAccidentDetailsViewEvent,
  trackClaimAccidentLocationSelectEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import {
  LocationInput,
  LocationInputHandlerParam,
} from '../../../components/controls/LocationInputReactGeosuggest';
import { goToNextPage } from '../../../routing/RouteActions';

export const AccidentDetails: React.FC<AccidentDetailsProps> = ({
  claimId,
  incident,
  accidentDetails,
  color,
  licensePlate,
  saveAccidentDetails,
  navigateHome,
  navigateToNextStep,
  isMotionsCloudEnabled,
  showInsuranceSelection,
}: AccidentDetailsProps) => {
  useEffect(() => {
    trackClaimAccidentDetailsViewEvent(claimId);
  }, []);

  const methods = useForm({
    defaultValues: {
      accidentDate: accidentDetails.date,
      accidentTime: accidentDetails.time,
      accidentLocation: accidentDetails.address,
      accidentDescription: accidentDetails.description,
      accidentCause: accidentDetails.whoCaused ? accidentDetails.whoCaused : undefined,
      notifiedAuthorities:
        accidentDetails.authoritiesNotified != undefined
          ? accidentDetails.authoritiesNotified
            ? 'yes'
            : 'no'
          : undefined,
      personalInjury:
        accidentDetails.personalInjury != undefined
          ? accidentDetails.personalInjury
            ? 'yes'
            : 'no'
          : undefined,
    },
  });
  const { register, handleSubmit, errors, formState } = methods;
  const geocoderRef: any = useRef(null);

  const beforeSubmit = async (data: React.BaseSyntheticEvent) => {
    handleLocationInvalid(location.label);
    setDateError(date == undefined);
    setTimeError(time == undefined || time == 'HH:MM');
    await onSubmit(data);
  };

  const parseDate = (dateStr: string, timeStr: string) => {
    const dateArr = dateStr.split(/[^0-9]/);
    const timeArr = timeStr.split(/[^0-9]/);
    return new Date(+dateArr[0], +dateArr[1] - 1, +dateArr[2], +timeArr[0], +timeArr[1]);
  };

  interface SubmitPayload {
    accidentDescription?: string;
    accidentCause?: ApiAccidentCulprit;
    notifiedAuthorities?: string;
    personalInjury?: string;
  }

  const onSubmit = handleSubmit(async (data: SubmitPayload) => {
    if (claimId && claimId.trim() != '') {
      try {
        const newAddress = geocoderRef.current.input.props.value;
        const isLocationInvalid = newAddress == undefined || newAddress.trim().length == 0;
        await setLocationInvalid(isLocationInvalid);
        if (isLocationInvalid) return;
        let theLocation = location;
        if (accidentDetails.address === newAddress) {
          // restore lat/lng from state.
          theLocation = {
            latitude: accidentDetails.location?.latitude
              ? accidentDetails.location?.latitude
              : location.latitude,
            longitude: accidentDetails.location?.longitude
              ? accidentDetails.location?.longitude
              : location.longitude,
            label: newAddress,
          };
        }

        if (date == undefined || time == undefined || time == 'HH:MM') {
          setDateError(true);
          return;
        }
        const datePart = date.split(' ')[0];
        const accidentDateTime = parseDate(datePart, time);

        const apiAccidentDetails: ApiAccidentDetails = {
          accidentDatetime: accidentDateTime,
          address: geocoderRef.current.input.props.value,
          location: { latitude: theLocation.latitude, longitude: theLocation.longitude },
          description: data.accidentDescription,
          whoCaused: data.accidentCause,
          authoritiesNotified:
            data.notifiedAuthorities != undefined && data.notifiedAuthorities === 'yes',
          personalInjury: data.personalInjury != undefined && data.personalInjury === 'yes',
        };
        await saveAccidentDetails(claimId, apiAccidentDetails);
        trackClaimAccidentDetailsSubmitEvent(claimId, accidentDetails);
        navigateToNextStep(incident, isMotionsCloudEnabled, showInsuranceSelection);
      } catch (error) {
        trackExceptionEvent(claimId, 'claim accident details submit', error.message);
        setErrorPopup(
          <ModalPopup
            message={<Translate value={'errors.savingAccidentDetailsFailed'} />}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
      }
    } else {
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.claimNotFound'} />}
          closeHandler={() => {
            setErrorPopup(<></>);
            navigateHome();
          }}
        />
      );
    }
  });
  const handleLocationInvalid = (newAddress: string) => {
    const isLocationInvalid = newAddress == undefined || newAddress.trim().length == 0;
    setLocationInvalid(isLocationInvalid);
  };

  const [location, setLocation] = useState({ latitude: 0, longitude: 0, label: '' });
  const onLocationSelect = (data: LocationInputHandlerParam) => {
    setLocation(data);
    handleLocationInvalid(data.label);
    trackClaimAccidentLocationSelectEvent(claimId, data.latitude, data.longitude);
  };

  const [errorPopup, setErrorPopup] = useState(<></>);
  const [isLocationInvalid, setLocationInvalid] = useState(false);

  const displayCulpritQuestion =
    incident === ApiClaimIncidentType.TrafficAccident || incident === ApiClaimIncidentType.Other;
  const displayAuthoritiesQuestion =
    incident !== ApiClaimIncidentType.WindshieldDamage &&
    incident !== ApiClaimIncidentType.TechnicalBreakdown;
  const displayInjuryQuestion =
    incident === ApiClaimIncidentType.TrafficAccident ||
    incident === ApiClaimIncidentType.HitWildAnimal ||
    incident === ApiClaimIncidentType.Other;

  const getDateString = (date: Date): string | undefined => {
    if (!date) return;
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${year}/${month + 1}/${day}`;
  };

  const [time, setTime] = useState(accidentDetails.time);
  const [date, setDate] = useState(
    accidentDetails.time == undefined
      ? accidentDetails.date
      : `${accidentDetails.date} ${accidentDetails.time}`
  );
  useEffect(() => {
    return () => {
      setTime('');
      setDate('');
    };
  }, []);

  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const onDateChange = (e: Date) => {
    const dateString = getDateString(e);
    setDate(dateString);
  };

  const onTimeChange = (timeString?: string) => {
    setTime(timeString);
  };

  return (
    <Layout hideLogo={false} showProgress>
      <Container className='content yaway-container' fluid>
        <SectionHeader text={<Translate value={'accidentDetails.title'} />} />
        <FormProvider {...methods}>
          <Form id='accidentDetailsForm' className='my-4' noValidate onSubmit={beforeSubmit}>
            <Form.Group className='my-4'>
              <div className={`date ${dateError ? 'error-border' : ''}`}>
                <DateInput
                  data-testid='date'
                  cssClass='date-pckr'
                  startDate={
                    date === undefined ? date : new Date(Date.parse(date.replace(/-/g, '/')))
                  }
                  label={
                    <Translate
                      value={'accidentDetails.date'}
                      style={{ color: 'grey', fontSize: '14px' }}
                    />
                  }
                  onChange={onDateChange}
                />
              </div>
              {dateError ? (
                <span className={'error-text'}>
                  <Translate value={'accidentDetails.dateError'} />{' '}
                </span>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className='my-4'>
              <TimeInput
                error={timeError}
                data-testid='time'
                startTime={time}
                label={
                  <Translate
                    value={'accidentDetails.time'}
                    style={{ color: 'grey', fontSize: '14px' }}
                  />
                }
                onChange={onTimeChange}
              />
            </Form.Group>

            <Form.Group className='my-4'>
              <LocationInput
                label={<Translate value={'accidentDetails.location'} />}
                onSelect={onLocationSelect}
                value={accidentDetails.address || ''}
                ref={geocoderRef}
              />
              {isLocationInvalid ? (
                <Form.Control.Feedback type='invalid' style={{ display: 'inherit' }}>
                  Location is mandatory
                </Form.Control.Feedback>
              ) : (
                <></>
              )}
            </Form.Group>

            <TextAreaInput
              name='accidentDescription'
              label={<Translate value={'accidentDetails.description'} />}
              ref={register({ required: true })}
              isInvalid={!!errors.accidentDescription}
              validationError={
                <Translate value={'accidentDetails.accidentDescriptionIsMandatory'} />
              }
            />

            {displayCulpritQuestion && (
              <Form.Group controlId='accidentCause' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.culprit.responsibleParty'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Me}
                    label={
                      <Translate
                        value={'accidentDetails.culprit.notifier'}
                        licensePlate={licensePlate}
                      />
                    }
                    id='accidentCause-driver'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
                <Form.Row>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Other}
                    label={<Translate value={'accidentDetails.culprit.otherParty'} />}
                    id='accidentCause-other-side'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
                <Form.Row className='d-flex justify-content-start'>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Unknown}
                    label={<Translate value={'accidentDetails.culprit.unknown'} />}
                    id='accidentCause-unknown'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
                <Form.Control.Feedback type='invalid'>REQ</Form.Control.Feedback>
              </Form.Group>
            )}

            {displayAuthoritiesQuestion && (
              <Form.Group controlId='notifiedAuthorities' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.authoritiesNotified'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    inline
                    name='notifiedAuthorities'
                    id='notifiedAuthorities-yes'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.affirmation'} />}
                    value={'yes'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.notifiedAuthorities}
                  />
                  <Radio
                    inline
                    name='notifiedAuthorities'
                    id='notifiedAuthorities-no'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.deny'} />}
                    value={'no'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.notifiedAuthorities}
                  />
                </Form.Row>
              </Form.Group>
            )}

            {displayInjuryQuestion && (
              <Form.Group controlId='personalInjury' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.personalInjury'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    inline
                    name='personalInjury'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.affirmation'} />}
                    id='personalInjury-yes'
                    value={'yes'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.personalInjury}
                  />
                  <Radio
                    inline
                    name='personalInjury'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.deny'} />}
                    id='personalInjury-no'
                    value={'no'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.personalInjury}
                  />
                </Form.Row>
              </Form.Group>
            )}
            {errorPopup}
          </Form>
        </FormProvider>
        <NextButton
          type='submit'
          form='accidentDetailsForm'
          data-testid='submitBtn'
          loading={formState.isSubmitting}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({
  claim,
  claimAccidentDetails,
  theme,
  claimIncident,
  vehicleDetails,
}: AppState) => ({
  claimId: claim.claimId,
  accidentDetails: claimAccidentDetails,
  color: theme.color,
  incident: claimIncident.incident,
  licensePlate: vehicleDetails.plateNumber,
  isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
  showInsuranceSelection: claim.showInsuranceSelection,
});

const mapDispatchToProp = {
  saveAccidentDetails: saveAccidentDetailsAction,
  navigateHome: () => push('/'),
  navigateToNextStep: goToNextPage,
};

export type AccidentDetailsProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(AccidentDetails);
