import React, { ChangeEvent, useEffect, useState } from 'react';
import '../../../common/components/incidents/incident.css';
import { AppState } from '../../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import VehicleInfoBox from '../../../common/components/vehicle-details/VehicleInfoBox';
import Layout from '../../../components/layouts/Layout';
import { Container, Form } from 'react-bootstrap';
import { TextInput } from '../../../components/controls/TextInput';
import { Translate } from 'react-redux-i18n';
import { getLocalizedMessageElement } from '../../../locale/locale';
import NextButton from '../../../components/controls/NextButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  plateNumberFormat,
  plateNumberValidationSchema,
  removeInvalidCharacters,
} from '../../../common/FormValidations';
import {
  getVehicleDetailsByPlateNumberAction,
  saveVehicleDetailsAction,
} from './vehicleDetailsAction';
import { trackClaimVehicleDetailsViewEvent } from '../../../analytics/Analytics';

const validationSchema = yup.object().shape({
  vehicleNumber: plateNumberValidationSchema,
});

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  vehicleDetails,
  insurance,
  claimId,
  getVehicleInfoByPlateNumber,
  saveVehicleDetails,
  incident,
  serviceContact,
  isMotionsCloudEnabled,
  themeColor,
}: VehicleDetailsProps) => {
  const { register, handleSubmit, errors, setValue, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vehicleNumber: vehicleDetails.plateNumber,
    },
  });
  const [loading, setLoading] = useState(false);
  const [previousNumber, setPreviousNumber] = useState('0');
  const [plateNumberInputChanged, setPlateNumberInputChanged] = useState(false);

  const onPlateNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPlateNumberInputChanged(true);
    setValue('vehicleNumber', removeInvalidCharacters(evt.currentTarget.value));
  };

  useEffect(() => {
    trackClaimVehicleDetailsViewEvent(claimId);
  }, []);

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.plateNumber) {
      if (vehicleDetails.plateNumber.match(plateNumberFormat) == null) return;

      setLoading(true);
      getVehicleInfoByPlateNumber(vehicleDetails.plateNumber, claimId).finally(() => {
        setLoading(false);
        setPreviousNumber(vehicleDetails.plateNumber);
      });
    }
  }, []);

  const searchVehicleDataByNumber = (evt: ChangeEvent<HTMLInputElement>) => {
    const plateNumber = evt.currentTarget.value;
    const isPlateNumberChanged = plateNumber.toUpperCase() != previousNumber.toUpperCase();
    setPlateNumberInputChanged(isPlateNumberChanged);
    if (!isPlateNumberChanged) return;
    if (plateNumber.match(plateNumberFormat) == null) return;

    setLoading(true);
    getVehicleInfoByPlateNumber(plateNumber, claimId).finally(() => {
      setLoading(false);
      setPreviousNumber(plateNumber);
      setPlateNumberInputChanged(false);
    });
  };

  const onSaveVehicleDetails = async () => {
    if (claimId) {
      await saveVehicleDetails(
        claimId,
        {
          licensePlate: vehicleDetails.plateNumber,
          vinCode: vehicleDetails.vinCode,
          mark: vehicleDetails.mark,
          model: vehicleDetails.model,
          year:
            vehicleDetails.year && parseInt(vehicleDetails.year)
              ? parseInt(vehicleDetails.year)
              : undefined,
        },
        incident,
        isMotionsCloudEnabled
      );
    }
  };

  return (
    <Layout showProgress>
      <Container className='content yaway-container mt-5' fluid>
        <Form id='contactForm' onSubmit={handleSubmit(onSaveVehicleDetails)}>
          <TextInput
            name='vehicleNumber'
            label={<Translate value={'enterVehicleNumber'} />}
            controlId='vehicleDetailsForm.vehicleNumber'
            onBlurHandler={searchVehicleDataByNumber}
            onEnterKeyHandler={(event: ChangeEvent<HTMLInputElement>) => event.currentTarget.blur()}
            ref={register}
            isInvalid={!!errors.vehicleNumber}
            validationError={getLocalizedMessageElement(errors.vehicleNumber?.message)}
            testId='vehicleNumber'
            cssClass='text-uppercase'
            onChangeHandler={onPlateNumberChange}
          />
          <VehicleInfoBox
            vehicleDetails={vehicleDetails}
            insurance={insurance.verified}
            loading={loading}
            themeColor={themeColor}
            directPhoneLine={serviceContact}
          />
        </Form>

        <NextButton
          type='submit'
          form='contactForm'
          data-testid='submitBtn'
          disabled={
            insurance.verified === false ||
            loading ||
            formState.isSubmitting ||
            plateNumberInputChanged
          }
          loading={formState.isSubmitting}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ vehicleDetails, insurance, claim, claimIncident, theme }: AppState) => ({
  vehicleDetails,
  insurance,
  claimId: claim.claimId,
  incident: claimIncident.incident,
  serviceContact: claim.serviceContact,
  isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
  themeColor: theme.color,
});

const mapDispatchToProps = {
  getVehicleInfoByPlateNumber: getVehicleDetailsByPlateNumberAction,
  saveVehicleDetails: saveVehicleDetailsAction,
};

export type VehicleDetailsProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);
