import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { Container, Row } from 'react-bootstrap';
import './DamageLocation.css';
import { NextButton } from '../../../components/controls/NextButton';
import { AppState } from '../../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import {
  getDamageAreaAction,
  getDamageVehicleTemplatesAction,
  saveDamageLocationsAction,
} from './damageLocationActions';
import * as htmlToImage from 'html-to-image';
import { Loader } from '../../../components/loader/Loader';
import { Translate } from 'react-redux-i18n';
import { ApiCoordinate } from '../../../api';
import {
  trackClaimDamageAreaSelectionEvent,
  trackClaimDamageViewEvent,
} from '../../../analytics/Analytics';
import { goToNextPage } from '../../../routing/RouteActions';
const SELECTED_AREA_COLOR_CODE = '#FA9704';

export const DamageLocation: React.FC<DamageLocationProps> = ({
  color,
  claimId,
  saveDamageLocations,
  template,
  getTemplates,
  getDamageArea,
  coordinates,
  incident,
  goToNextPage,
}: DamageLocationProps) => {
  const [selectedAreas, setSelectedAreas] = useState<Map<string, string | undefined>>(new Map());
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [imageDataUrl, setImageDataUrl] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (e: {
    target: {
      id: string;
    };
  }) => {
    if (isSubmitting) {
      return;
    }
    const id = e?.target?.id;
    if (id) {
      const areaColor =
        selectedAreas?.get(id) !== SELECTED_AREA_COLOR_CODE ? SELECTED_AREA_COLOR_CODE : undefined;
      setSelectedAreas(new Map(selectedAreas.set(id, areaColor) as Map<string, string>));
    }
    trackClaimDamageAreaSelectionEvent(claimId, id);
  };

  useEffect(() => {
    trackClaimDamageViewEvent(claimId);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (claimId) {
      getDamageArea(claimId);
    }
    setLoading(false);
  }, [claimId]);

  useEffect(() => {
    setLoading(true);
    getTemplates();
    setLoading(false);
  }, [getTemplates]);

  useEffect(() => {
    if (template && template.imageUrl) {
      const saveTemplateAsBase64 = async () => {
        const blob = await fetch(template.imageUrl).then((r) => r.blob());
        const dataUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
        setImageDataUrl(dataUrl as any);
      };

      saveTemplateAsBase64();
    }
  }, [template?.imageUrl]);

  useEffect(() => {
    const coordinateMap = new Map<string, string>();
    coordinates.forEach((item: ApiCoordinate) =>
      coordinateMap.set(`${item.x}-${item.y}`, SELECTED_AREA_COLOR_CODE)
    );
    setSelectedAreas(coordinateMap);
  }, [coordinates]);

  const hasAreaChanged = (selectedCoordinates: string[]) => {
    selectedCoordinates = selectedCoordinates.sort();
    const existingCoordinates = Array.from(
      coordinates,
      (item: ApiCoordinate) => `${item.x}-${item.y}`
    ).sort();

    return (
      existingCoordinates.length !== selectedCoordinates.length ||
      existingCoordinates.some((value, index) => value !== selectedCoordinates[index])
    );
  };

  const handleSubmit = () => {
    if (claimId && template && template.id && ref.current) {
      const element = ref.current;
      element.style.setProperty('background', 'none');
      element.style.setProperty('background', `url(${imageDataUrl}) no-repeat center center`);
      const selectedCoordinates = Array.from(selectedAreas.entries())
        .filter(({ 1: value }) => value === SELECTED_AREA_COLOR_CODE)
        .map(([key]) => key);

      if (hasAreaChanged(selectedCoordinates)) {
        setSubmitting(true);
        htmlToImage
          .toPng(element, { style: { margin: '0' } })
          .then(async (image: string) => {
            await saveDamageLocations(claimId, selectedCoordinates, image, template.id, incident);
          })
          .finally(() => setSubmitting(false));
      } else {
        setSubmitting(false);
        goToNextPage(incident);
      }
    }
  };

  const calculatePaddingBottom = (row: number, col: number) => {
    return `${((row / col) * 100).toFixed(5).toString()}%`;
  };

  const calculateWidth = (col: number) => {
    return `${(100 / col).toFixed(5).toString()}%`;
  };

  return (
    <Layout showProgress>
      <Container className='content yaway-container' fluid>
        <SectionHeader text={<Translate value={'damageLocation.title'} />} />
        {loading ? (
          <Loader />
        ) : (
          <div
            className='custom-container container-border mt-3'
            style={{ background: `url(${imageDataUrl}) no-repeat center center` }}
            ref={ref}
          >
            {template && template.imageUrl && template.columns && template.rows ? (
              <div
                className='position-relative content-wrapper'
                title={'damage-location-grid'}
                style={{ paddingBottom: calculatePaddingBottom(template.rows, template.columns) }}
              >
                {Array.from(Array(template?.rows).keys()).map((row: number) => (
                  <Row
                    key={row}
                    className={`cell-height position-relative`}
                    style={{ paddingBottom: calculateWidth(template?.columns) }}
                  >
                    {Array.from(Array(template?.columns).keys()).map((col: number) => (
                      <div
                        className={`custom-col`}
                        id={`${row}-${col}`}
                        title={`${row}-${col}`}
                        // @ts-ignore
                        onClick={handleClick}
                        style={{
                          backgroundColor: selectedAreas?.get(`${row}-${col}`),
                          opacity: selectedAreas?.has(`${row}-${col}`) ? '50%' : undefined,
                          paddingBottom: calculateWidth(template?.columns),
                          width: calculateWidth(template?.columns),
                        }}
                        key={col}
                      />
                    ))}
                  </Row>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        <NextButton
          color={color}
          onClick={handleSubmit}
          data-testid='submit'
          loading={loading || isSubmitting}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ theme, claim, damageLocation, claimIncident }: AppState) => ({
  color: theme.color,
  claimId: claim.claimId,
  template: damageLocation.selectedTemplate,
  coordinates: damageLocation.coordinates,
  incident: claimIncident.incident,
});

const mapDispatchToProps = {
  saveDamageLocations: saveDamageLocationsAction,
  getTemplates: getDamageVehicleTemplatesAction,
  getDamageArea: getDamageAreaAction,
  goToNextPage: goToNextPage,
};

export type DamageLocationProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DamageLocation);
