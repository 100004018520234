import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { AppState } from '../../../store/store';
import { Translate } from 'react-redux-i18n';
import { NextButton } from '../../../components/controls/NextButton';
import { getInsuranceInfo, saveInsuranceInfo } from './insuranceActions';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import './insuranceSelection.css';
import { goToNextPage } from '../../../routing/RouteActions';
import { ApiAccidentCulprit, ApiClaimIncidentType, ApiCountryCode } from '../../../api';

export const InsuranceSelection: React.FC<InsuranceSelectionProps> = ({
  claim,
  color,
  incident,
  countryCode,
  accidentDetails,
  navigateToNextStep,
  saveInsuranceInfo,
  getInsuranceInfo,
}: InsuranceSelectionProps) => {
  const [casco, setCasco] = React.useState('');
  const [mtpl, setMtpl] = React.useState('');
  const [showMtpl, setShowMtpl] = React.useState<boolean>();
  const [showCasco, setShowCasco] = React.useState<boolean>();
  useEffect(() => {
    getInsuranceInfo(Object.values(ApiCountryCode).find((code) => code === countryCode));
    setShowMtpl(incident == ApiClaimIncidentType.TrafficAccident);
    setShowCasco(
      accidentDetails.whoCaused != ApiAccidentCulprit.Other ||
        incident != ApiClaimIncidentType.TrafficAccident
    );
  }, []);

  useEffect(() => {
    if (claim.selectedCascoId != null && claim.cascoInsurances != undefined) {
      if (claim.selectedCascoId == -1) {
        setCasco(claim.selectedCascoId.toString());
      } else {
        const selected = claim.cascoInsurances.find(
          (insurance) => insurance.id === claim.selectedCascoId
        );

        if (selected != undefined) {
          setCasco(selected.id.toString());
        }
      }
    }
    if (claim.selectedMtplId != null && claim.mtplInsurances != undefined) {
      if (claim.selectedMtplId == -1) {
        setMtpl(claim.selectedMtplId.toString());
      } else {
        const selected = claim.mtplInsurances.find(
          (insurance) => insurance.id === claim.selectedMtplId
        );

        if (selected != undefined) {
          setMtpl(selected.id.toString());
        }
      }
    }
  }, [claim]);
  const [loading, setLoading] = useState<boolean>(false);

  const submitSelections = async () => {
    setLoading(true);
    if (showMtpl && mtpl.length == 0) {
      setLoading(false);
      return;
    }

    if (claim.claimId != undefined) {
      await saveInsuranceInfo(mtpl, casco, claim.claimId);
    }
    setLoading(false);

    if (
      incident == ApiClaimIncidentType.TrafficAccident &&
      accidentDetails.whoCaused != ApiAccidentCulprit.Me
    ) {
      navigateToNextStep(incident, false, undefined, mtpl == '-1' || mtpl == '');
      return;
    }
    navigateToNextStep(incident, false, undefined, casco == '-1' || casco == '');
  };
  const handleCascoSelection = (event: SelectChangeEvent) => {
    const selection = event.target.value;
    setCasco(selection);
  };

  const handleMtplSelection = (event: SelectChangeEvent) => {
    const selection = event.target.value;
    setMtpl(selection);
  };

  return (
    <Layout showProgress>
      <Container className='content yaway-container' fluid>
        <SectionHeader text={<Translate value={'insuranceSelection.title'} />} />
        <h6 className='text-center text-default-color p-2 my-2'>
          {<Translate value={'insuranceSelection.subtitle'} />}
        </h6>
        <>
          {showCasco && (
            <Box className='m-5' sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel style={{ color: 'gray' }}>
                  {<Translate value={'insuranceSelection.casco'} />}
                </InputLabel>
                <Select
                  variant='standard'
                  value={casco}
                  onChange={handleCascoSelection}
                  label={<Translate value={'insuranceSelection.casco'} />}
                  className='select-options-navbar-main-color'
                >
                  {claim.cascoInsurances?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                  <MenuItem key='-1' value='-1'>
                    {<Translate value={'insuranceSelection.CascoNoneApplicable'} />}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </>
        <>
          {showMtpl && (
            <Box className='m-5' sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel style={{ color: 'gray' }}>
                  {<Translate value={'insuranceSelection.mtpl'} />}
                </InputLabel>
                <Select
                  variant='standard'
                  value={mtpl}
                  onChange={handleMtplSelection}
                  label={<Translate value={'insuranceSelection.mtpl'} />}
                  className='select-options-navbar-main-color'
                >
                  {claim.mtplInsurances?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                  <MenuItem key='-1' value='-1'>
                    {<Translate value={'insuranceSelection.MtplNotSure'} />}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </>
        <NextButton
          color={color}
          className='my-2'
          onClick={submitSelections}
          data-testid='submit'
          loading={loading}
          disabled={(showCasco && casco == '') || (showMtpl && mtpl == '')}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({
  claim,
  theme,
  claimIncident,
  locale,
  claimAccidentDetails,
}: AppState) => ({
  claim: claim,
  color: theme.color,
  incident: claimIncident.incident,
  countryCode: locale.countryCode,
  accidentDetails: claimAccidentDetails,
});

const mapDispatchToProp = {
  navigateToNextStep: goToNextPage,
  saveInsuranceInfo: saveInsuranceInfo,
  getInsuranceInfo: getInsuranceInfo,
};

export type InsuranceSelectionProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(InsuranceSelection);
